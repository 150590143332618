@import "../../styles/mixins/index.scss";

.slider {
  width: 100%;
  &__img {
    padding-top: 110px;
    padding-bottom: 110px;
    @include sm {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    img {
      border-radius: 50%;
      width: 299px;
      transition: transform .3s;
    }
  }
}

.btns {
  display: flex;
  gap: 20px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  &__icon {
    &_right {
      transform: rotate(59deg);
      transform-origin: 8px 0;
    }
  }
}
