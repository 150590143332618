@import "../../styles/mixins/index.scss";

.header {
  background: rgba(239, 35, 141, 0.7);
  border-radius: 12px;
  position: relative;
  padding: 15px 50px 15px 280px;
  text-transform: uppercase;
  @include xl {
    padding-left: 240px;
    padding-right: 20px;
  }
  @include sm {
    padding-left: 95px;
  }
  @include xs {
    padding-left: 50px;
  }
  &_fixed {
    position: fixed;
    z-index: 10000;
    left: 0;
    width: 100%;
    border-radius: 0;
    margin-top: -54px;
    @include sm {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .header__logo {
      width: 94px;
      left: 80px;
      top: 0;
      @include lg {
        left: 0;
      }
      @include sm {
        width: 120px;
        left: 0;
      }
    }
    .social__icon {
      width: 40px;
      height: 40px;
      padding: 10px;
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include xl {
      gap: 20px;
    }
    @include lg {
      justify-content: flex-end;
      gap: 20px;
    }
  }
  &__logo {
    position: absolute;
    left: 80px;
    top: -55px;
    transition: 0.3s;
    @include xl {
      left: 0;
    }
    @include sm {
      width: 165px;
      top: -54px;
    }
    @include xs {
      width: 116px;
    }
  }
  &__btn-get {
    display: block;
    font-size: 22px;
    font-weight: bold;
    color: var(--white);
    text-transform: uppercase;
    padding: 5px 10px;
    background-color: var(--blue);
    text-align: center;
    background-image: linear-gradient(
      20deg,
      var(--blue) 20%,
      #8e70de 40%,
      var(--blue-sec) 100%
    );
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    &_bg {
      border-width: 3px;
      border-style: solid;
      border-image: linear-gradient(to right, var(--blue), var(--blue-sec)) 1;
    }
  }
}

.menu {
  display: flex;
  gap: 45px;
  align-items: center;
  @include lg {
    flex-direction: column;
    position: absolute;
    gap: 16px;
    top: 80px;
    display: none;
    z-index: 1000;
    a {
      font-size: 28px;
    }
    &__open {
      display: flex;
      width: 100%;
      left: 0;
      top: 70px;
      padding-inline-start: 20px;
      background-color: var(--black);
      height: 100vh;
      padding-block-start: 20px;
      padding-block-end: 20px;
      overflow: auto;
      @include sm {
        top: 90px;
      }
    }
  }
  &__link {
    color: var(--white);
    font-size: 22px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.social {
  display: flex;
  align-items: center;
  gap: 22px;
  @include xl {
    gap: 10px;
  }
  &__icon {
    box-shadow: var(--social-shadow);
    transition: box-shadow 0.3s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--pink-sec);
    height: 50px;
    width: 50px;
    transition: width 0.3s, height 0.4s;
    color: var(--white);
    @include sm {
      height: 30px;
      width: 30px;
      padding: 5px;
    }
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
  }
  strong {
    display: none;
  }
}

.burger {
  &__btn {
    cursor: pointer;
    display: none;
    background: none;
    @include lg {
      display: block;
      height: 24px;
      width: 30px;
    }
  }
  &__icon {
    display: block;
    background-color: var(--white);
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    position: absolute;

    &:before {
      @extend .burger__icon;
      content: "";
      margin-top: -8px;
    }
    &::after {
      @extend .burger__icon;
      content: "";
      margin-top: 8px;
    }
    &_open {
      background: transparent;
      &::before {
        margin-top: 0px;
        transform: rotate(405deg);
      }
      &::after {
        margin-top: 0px;
        transform: rotate(-405deg);
      }
    }
  }
}


.btn a{
  color:black;
}
