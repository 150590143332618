@import "../../styles/mixins/index.scss";

.footer {
  background-image: url("../../assets/image/footer/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block-start: 300px;
  padding-block-end: 48px;
  text-align: center;
  position: relative;
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    @include lg {
      display: none;
    }
  }
  @include sm {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
  &__title {
    margin-bottom: 30px;
  }
  &__wrap {
    position: relative;
  }
  &__logo {
    position: absolute;
    left: 0;
    top: -100px;
    display: none;
    @include lg {
      position: initial;
      display: block;
      margin: auto;
    }
  }
}
.social {
  display: flex;
  gap: 22px;
  justify-content: center;
  align-content: center;
  margin-bottom: 30px;
  &__icon {
    display: inline-block;
    color: var(--white);
    border: 1px solid var(--pink);
    box-shadow: 0px 0px 10px 2px var(--pink-sec);
    transition: 0.3s box-shadow;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: none;
    }
  }
}

.menu {
  margin-bottom: 100px;
  &__item {
    margin-bottom: 20px;
  }
  &__link {
    color: var(--white);
    text-transform: uppercase;
  }
}
