@import "../../styles/mixins/index.scss";

.train {
  position: relative;
  background-image: url("../../assets/image/training/bg.png");
  background-color: var(--black);
  background-position: right, center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-block-start: 200px;
  padding-block-end: 200px;
  h2 {
    margin-bottom: 40px;
  }
  &__wrap {
    display: flex;
    gap: 40px;
    align-items: center;
    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__address {
    font-weight: 800;
    border: solid 1px var(--white);
    border-radius: 12px;
    padding: 14px 44px 14px 26px;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__status {
    color: var(--pink);
    margin-top: 10px;
  }
  @include sm {
    background-position: center;
    padding-block-start: 60px;
    padding-block-end: 60px;
    background-size: cover;
  }
}
