@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../mixins/index.scss";

:root {
  --black: #000000;
  --black-sec: #222222;
  --white: #ffffff;
  --pink: #ef238d;
  --pink-sec: #ff00e5;
  --blue: #0ee3f5;
  --blue-sec: #030057;
  --green: #a6fd1b;
  --red: #c84f4f;
  --gray: #eee;
  --orange: #fa6c3d;
  --violet: #6461e2;
  --social-shadow: 0px 0px 10px 2px var(--pink);
}

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 90px;
}

body {
  font-family: "Archivo", Arial;
  color: var(--white);
  font-size: 18px;
}

h1,
.h1 {
  font-size: 60px;
  font-weight: bold;
  color: var(--pink);
  margin-bottom: 40px;
  @include sm {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

h2 {
  font-size: 40px;
  font-weight: 700;
  @include sm {
    font-size: 22px;
  }
}

.container {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1320px;

  @include xxl {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    max-width: initial;
    width: 100%;
  }
}

.text-blue {
  color: var(--blue);
}

.text-pink {
  color: var(--pink);
}
.text-pink-sec {
  color: var(--pink-sec);
}

.text-green {
  color: var(--green);
}

.btn {
  background-color: var(--green);
  color: var(--black);
  border-radius: 12px;
  padding: 14px 44px;
  font-weight: 800;
  font-size: 18px;
  transition: opacity 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  &:hover {
    opacity: 0.9;
  }
}

.shop{
  background-color: Black;
  color: white;
  border-radius: 12px;
  padding: 14px 44px;
  font-weight: 800;
  font-size: 18px;
  transition: opacity 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  &:hover {
    opacity: 0.9;
  }

}

.shop a{
  color: white!important;
}

// Swiper
.swiper-slide-active {
  img {
    transform: scale(1.3);
    @include sm {
      transform: scale(1);
    }
  }
}

.relative {
  position: relative;
  z-index: 10;
}

.text-outline {
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--blue);
}

.sold-out{
  display: flex;
    position: relative;
    justify-content: center;
    margin-top: -20px;

}

.sold-out-text{
  display: block;
    font-size: 22px;
    font-weight: bold;
    color: var(--blue);
    text-transform: uppercase;
    padding: 5px 10px;
    // background-color: var(--blue);
    text-align: center;
    // background-image: linear-gradient(
    //   20deg,
    //   var(--blue) 20%,
    //   #8e70de 40%,
    //   var(--blue-sec) 100%
    // );
    background-size: 100%;
    background-repeat: repeat;
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
   
      border-width: 3px;
      border-style: solid;
      border-image: linear-gradient(to right, var(--blue), var(--blue-sec)) 1;
   
}