@import "../../styles/mixins/index.scss";

.card {
  width: calc(100% / 4 - 32px);
  @include sm {
    width: calc(100% / 2 - 10px);
  }
  &__name {
    font-weight: 800;
    margin-bottom: 20px;
  }
  &__img {
    width: 100%;
    max-width: 300px;
    border-radius: 12px;
    margin-bottom: 30px;
    max-height: 450px;
  }
  &__icon {
    display: inline-block;
    color: var(--white);
    border: 1px solid var(--pink);
    box-shadow: 0px 0px 10px 2px var(--pink-sec);
    transition: 0.3s box-shadow;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: none;
    }
  }
  &__position {
    margin-bottom: 16px;
  }
}
