.modal {
  max-width: 500px;
  width: 100%;
  position: absolute;
  background-color: #e82188cc;
  padding: 34px 24px 24px 24px;
  margin: 0 auto;
  border-radius: 12px;
  color: var(--white);
  animation: .6s topToBottom;
  &__header {
    margin-bottom: 20px;
    h2 {
      font-size: 28px;
      text-align: center;
    }
  }
  &__close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    color: var(--white);
    transition: .3s transform;
    &:hover {
      transform: rotate(360deg);
    }
  }
}

.overlay {
  background-color: #0009;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000000;
}

@keyframes topToBottom {
  0% {
    opacity: 0;
    transform: translateY(-100vh);
  }
  60% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
