@import "../../styles/mixins/index.scss";

.intro {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 985px;
  overflow: hidden;
  background-color: #020207;
  padding-top: 54px;
  @include lg {
    background-image: url("../../assets/image/intro/bg.jpg");
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 985px;
    object-fit: cover;
    object-position: center 20px;
    @include xxxl {
      object-position: center 90px;
    }
    @include lg {
      display: none;
    }
  }
  @include sm {
    padding: 54px 0 0;
  }
  &__wrap {
    margin-top: 50px;
    @include sm {
      margin-top: 16px;
    }
  }
  &__title {
    color: var(--white);
    text-align: center;
    position: relative;
    text-transform: uppercase;
    z-index: 10;
    letter-spacing: 3px;
    span {
      font-weight: 900;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--blue);
    }
    @include sm {
      background-color: rgba(21, 0, 133, 0.6);
      border-radius: 12px;
      padding: 12px;
    }
  }
}

.video {
  background-image: url("../../assets/image/video/bg.png"),
    url("../../assets/image/video/b-light.png");
  background-repeat: no-repeat;
  background-size: cover, contain;
  background-position: center, bottom;
  text-align: center;
  padding-block-start: 104px;
  padding-block-end: 104px;
  position: relative;
  &__player_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    @include lg {
      display: none;
    }
  }
  &__title {
    margin-bottom: 60px;
    position: relative;
    z-index: 10;
    @include sm {
      margin-bottom: 20px;
    }
  }
  &__player {
    border-radius: 12px;
    max-width: 1000px;
    height: 400px;
    margin: 0 auto 70px auto;
    position: relative;
    @include lg {
      max-width: 100%;
      height: 200px;
    }
    @include sm {
      margin-bottom: 20px;
    }
  }
}

.guilty {
  background-image: url("../../assets/image/guilty/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 300px 0;
  position: relative;
  &__bg {
    margin-bottom: 40px;
    display: none;
    @include sm {
      display: block;
    }
  }
  @include sm {
    background: var(--black);
    text-align: center;
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
    @include lg {
      display: none;
    }
  }
  @include sm {
    padding: 0 0 50px;
  }
  &__title {
    margin-bottom: 0;
    strong {
      font-weight: bold;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--pink);
    }
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    @include md {
      flex-direction: column;
    }
  }
  &__text-block {
    max-width: 410px;
    & > p:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.story {
  background-color: var(--black);
  padding-block-start: 132px;
  text-align: center;
  position: relative;
  h2 {
    strong {
      font-weight: bold;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--pink);
    }
  }
  &__video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: 64%;
    @include lg {
      display: none;
    }
  }
  @include sm {
    padding: 50px 0;
  }
  &__subtitle {
    color: var(--green);
    font-size: 30px;
    margin-bottom: 40px;
    font-weight: 800;
  }
  &__text {
    max-width: 850px;
    margin: 0 auto 32px auto;
    & > p {
      margin-bottom: 20px;
    }
  }
  &__wrap {
    background-image: url("../../assets/image/guilty/bg-bottom.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-block-end: 500px;
    @include sm {
      padding-block-end: 0;
      background-image: none;
    }
    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #170035 100%);
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;
      background-color: var(--black);
      @include sm {
        background: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        var(--black) 100%
      );
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
    }
  }
}

.team {
  position: relative;
  background-color: var(--black);
  text-align: center;
  padding: 80px 0;
  h2 {
    strong {
      font-weight: bold;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: var(--pink);
    }
  }
  @include sm {
    padding: 0 0 40px;
  }
  &__desc {
    max-width: 852px;
    margin: 0 auto 90px;
    & > p {
      margin-bottom: 10px;
    }
  }
  &__staff {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    text-align: left;
    flex-wrap: wrap;
    @include sm {
      gap: 45px 10px;
    }
  }
}

.wow {
  background-color: var(--black);
  padding-block-start: 40px;
  padding-block-end: 40px;
  &-animation {
    background-image: url("../../assets/image/wow-gradient.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  @include sm {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    @include sm {
      flex-direction: column;
      text-align: center;
    }
  }
  &__text {
    margin-bottom: 40px;
    max-width: 400px;
    font-size: 22px;
    p {
      margin-bottom: 10px;
    }
  }
  &__bg {
    margin-top: 50px;
    width: 100%;
  }
}

.faq {
  background-image: url("../../assets/image/faq/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block-start: 80px;
  padding-block-end: 80px;
  @include sm {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
  &__title {
    color: var(--black);
    margin-bottom: 42px;
    padding: 0 110px;
    @include md {
      padding: 0;
    }
  }
}
