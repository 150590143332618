@import "../../styles/mixins/index.scss";

.timer {
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    padding: 20px 0;
    @include sm {
        text-align: center;
        background-color: var(--pink);
    }
    &__title {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    &__item {
        text-align: center;
        min-width: 62px;
    }
    &__value {
        font-size: 60px;
        font-weight: 700;
        @include sm {
            font-size: 28px;
        }
        &_type {
            margin-bottom: 0;
        }
    }
}

.time-wrap {
    display: flex;
    align-items: center;
    gap: 60px;
    @include sm {
        gap: 20px;
        justify-content: center;
    }
}