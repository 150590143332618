.privacy {
    background-color: var(--black);
    min-height: 100vh;
    padding: 54px 0;
    &__text {
        padding-top: 80px;
        h2 {
            color: var(--pink);
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 18px;
        }
        ul {
            li {
                list-style-type: disc;
                margin-left: 20px;
            }
            margin-bottom: 12px;
        }
    }
}