.wow {
    background-image: url('../../assets/image/wow-bg.png');
    height: 154px;
    margin: 80px 0 40px;
    background-size: contain, cover;
    background-repeat: repeat-x, no-repeat;
    position: relative;
    &:before {
        content: '';
        background-image: url('../../assets/image/wow-gradient.png');
        width: 100%;
        height: 100%;
    }
}