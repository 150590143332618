@import "../../styles/mixins/index.scss";
.card {
  padding: 22px 110px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  margin-bottom: 8px;
  @include md {
    padding: 22px;
  }
  &__name {
    font-weight: 800;
    margin-bottom: 10px;
    color: var(--green);
  }
  &__desc {
    font-size: 18px;
  }
}
