@import './reset/index.scss';
@import './global/index.scss';

iframe {
    display: none;
}

#root iframe {
    display: block;
}

.form-counter{
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fff;
    margin-block-start: 30px;
    font-size: 40px;
    gap: 15px;
    color: #fff;
    span{
        width: 20px;
    }
    .btn{
        position: absolute;
        bottom: 5px;
        right: 0;
    }
}

.counter-btn{
    align-self: center;
    font-size: 40px;
    line-height: 1;
    color: #fff
  }
  
  .counter-btn[data-pos="minus"] {
    position: relative;
    top: -3px;
  }

  .btn--connect{
    margin-block-start: 30px;
  }