@import "../../styles/mixins/index.scss";

.mint {
  min-height: 60vh;
  padding-block-start: 150px;
  padding-block-end: 52px;
  background-image: url("../../assets/image/mint/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  font-weight: 800;
  H2 {
    font-size: 38px;
  }
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
    @include lg {
      flex-direction: column;
      gap: 100px;
    }
  }
  &-price {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 34px;
    @include sm {
      font-size: 22px;
    }
    &__title {
      color: var(--green);
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 12px;
      font-size: 32px;
    }
    &__max {
      &:not(:first-child) {
        margin-bottom: 20px;
      }
      font-weight: 800;
    }
  }
  &__info {
    h3 {
      font-size: 26px;
      margin-bottom: 10px;
    }
    p {
      text-overflow: ellipsis;
      width: 210px;
      overflow: hidden;
      margin: auto;
      margin-bottom: 6px;
    }
  }
  &__btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    margin-block-start: 30px;
    > button {
      flex-shrink: 0;
      @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.wert {
  min-width: 500px;
  &__title {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 34px;
    line-height: 48px;
    margin-bottom: 100px;
    &_mb-20 {
      margin-bottom: 20px;
    }
  }
  @include sm {
    min-width: initial;
    width: 100%;
  }
}
